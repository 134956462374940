import type { CustomRecordRaw } from "@/router";

export const DocumentRoutes: CustomRecordRaw[] = [
  {
    name: "document_bk",
    path: "/document/bk",
    component: "iframe-page/[url]",
    props: {
      url: "https://bosok.brightuprise.com",
    },
    meta: {
      title: "宝库",
      i18nKey: "route.document_bk",
      order: 1,
      icon: "eva:book-open-outline",
    },
  },
  {
    name: "document_podo",
    path: "/document/podo",
    component: "iframe-page/[url]",
    props: {
      url: "https://namu.brightuprise.com/",
    },
    meta: {
      title: "葡萄系统",
      i18nKey: "route.document_podo",
      order: 2,
      icon: "streamline-emojis:grapes",
    },
  },
  {
    name: "document_multiple_scb",
    path: "/document/multiple/scb",
    component: "multiple-iframe-page/index",
    props: {
      urls: [
        {
          url: "https://www.shzion.org/v2",
          width: "33%",
        },
        {
          url: "https://www.shzion.org/music",
          width: "33%",
        },
        {
          url: "https://namu.brightuprise.com",
          width: "33%",
        },
      ],
    },
    meta: {
      title: "恩典甘露&赞美网",
      i18nKey: "route.document_scb" as App.I18n.I18nKey,
      order: 3,
      icon: "la:music",
    },
  },
];
